import React, { useEffect, useState } from "react";
import callApi from "../services/apiService";

interface Item {
  mcid: string;
  title: string;
  updated: Date;
}

const ListAllItems: React.FC = () => {
  const [items, setItems] = useState<Item[]>();

  useEffect(() => {
    const fetchSiteList = async () => {
      try {
        const items: Item[] = await callApi.getAllMessageCenterItems();
        setItems(items);
      } catch (error) {
        console.error("Failed to fetch site list:", error);
      }
    };

    fetchSiteList();
  }, []);

  return (
    <>
      {items?.map((item) => {
        const title = item.mcid + ": " + item.title;
        return (
          <li key={item.mcid}>
            <a href={`${window.location.origin}/${item.mcid}`}>{title}</a>
          </li>
        );
      })}
    </>
  );
};

export default ListAllItems;
