import React from "react";
import TopMenuBar from "./TopMenuBar";
import ListAllItems from "./ListAllItems";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

const AllItemsPage: React.FC = () => {
  return (
    <>
      <TopMenuBar />
      <div>
        <Container>
          <Box component="section" sx={{ p: 2 }}>
            <ListAllItems />
          </Box>
        </Container>
      </div>
    </>
  );
};

export default AllItemsPage;
