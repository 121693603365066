import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import callApi from "../services/apiService";
import { Helmet } from "react-helmet";
//import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TopMenuBar from "./TopMenuBar";
import { Button } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";

//import { WistiaPlayer } from "@wistia/wistia-player-react";

interface Params extends Record<string, string> {
  param: string;
}

interface Params extends Record<string, string> {
  param: string;
}

const MessageCenterDetail: React.FC = () => {
  let { param } = useParams<Params>();
  const minDate = "1900-01-01T00:00:00";
  const [messageCenterItem, setMessageCenterItem] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [openGraphTitle, setOpenGraphTitle] = useState("");
  const [descriptionPoints, setDescriptionPoints] = useState<string[]>([]);
  const [openGraphImageUrl, setOpenGraphImageUrl] = useState<string | null>(
    null
  );
  // const [images, setImages] = useState<string[]>([]);
  const [services, setServices] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  //const [events, setEvents] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const effectRan = useRef(false);

  const openGraphImageUrlFallback = openGraphImageUrl
    ? openGraphImageUrl
    : "https://changepilot.cloud/hubfs/Logo%20PNGs/ChangePilotMint1000px.png";

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: metaTitle,
    alternateName: "ChangePilot from Empowering Cloud",
    url: getUrl(),
    image: openGraphImageUrlFallback,
  };

  const ImpactChip = (impact: number) => {
    if (impact === 1) {
      return <Chip label="Low" className="impactLow" />;
    } else if (impact === 2) {
      return <Chip label="Medium" className="impactMedium" />;
    } else if (impact === 3) {
      return <Chip label="High" className="impactHigh" />;
    } else {
      return <Chip label="Unknown" />;
    }
  };

  interface TextWithHeadingsProps {
    text: string;
  }

  const TextWithHeadings: React.FC<TextWithHeadingsProps> = ({ text }) => {
    // Use regular expression to find parts of the text that are enclosed in square brackets
    const regex = /\[([^\]]+)\]/g;

    // Create an array to store the parts of the text
    const parts: React.ReactNode[] = [];
    let lastIndex = 0;
    let match: RegExpExecArray | null;

    while ((match = regex.exec(text)) !== null) {
      // Push text before the match
      if (match.index > lastIndex) {
        parts.push(text.slice(lastIndex, match.index));
      }

      // Push the <h3> heading
      parts.push("<h3 key=" + match.index + ">" + match[1] + "</h3>");

      // Update lastIndex to continue after this match
      lastIndex = regex.lastIndex;
    }

    // Push remaining text if any
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }

    //return <div>{parts}</div>;
    return (
      <Box marginTop={3}>
        <div
          dangerouslySetInnerHTML={{
            __html: parts.join(""),
          }}
        />
      </Box>
    );
  };

  useEffect(() => {
    if (effectRan.current) {
      return;
    }
    effectRan.current = true;

    const fetchMessageCenterItem = async () => {
      try {
        const item = await callApi.getMessageCenterItem(
          param?.toUpperCase() || ""
        );
        setIsLoading(false);
        if (item != null) {
          // const events = await callApi.getMessageCenterEvents(
          //   param?.toUpperCase() || ""
          // );
          setMessageCenterItem(item);
          // setEvents(events);
          setMetaTitle(GenerateMetaTitle(item.Id, item.Title));
          setOpenGraphTitle(`${item.Id} - ${item.Title} on ChangePilot`);
          setMetaDescription(
            `${item.Id} - ${item.Title}; Category: ${item.Category}; Affected Services: ${item.Services}`
          );
          // setImages(item.Images.split(","));
          setServices(item.Services.split(","));
          setTags(item.Tags.split(","));
          setDescriptionPoints(item.CuratedDescription.split("- "));
          setOpenGraphImageUrl(item.Images.split(",")[0]);
          //console.log("Message Center Item:", item);
        }
      } catch (error: any) {
        if (error.response.status !== 404) {
          console.error("Error fetching message center item:", error);
          setError("There was an issue fetching the message center item.");
        }
        setIsLoading(false);
      }
    };

    if (messageCenterItem == null) {
      fetchMessageCenterItem();
    }
  }, [param, messageCenterItem]);

  function getUrl(): string {
    if (typeof window == "undefined") return "";
    return window.location.href;
  }

  const GenerateMetaTitle = (id: string, title: string) => {
    const maxLength = 56;
    const idLength = id.length + 3;
    const suffix = " - ChangePilot";
    const titleLength = title.length;
    const titleRemaining = maxLength - idLength - suffix.length;
    if (titleLength > titleRemaining) {
      return `${id} - ${title.substring(0, titleRemaining)}...${suffix}`;
    }
    return `${id} - ${title} ${suffix}`;
  };

  // const PropertyContainer = styled(Paper)(({ theme }) => ({
  //   backgroundColor: "silver",
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   verticalAlign: "middle",
  //   color: theme.palette.text.secondary,
  //   minHeight: "100px",
  //   position: "relative",
  // }));

  return (
    <>
      <TopMenuBar />
      <div>
        {!error && messageCenterItem && (
          <>
            <Container maxWidth="lg">
              <Helmet>
                <title>{metaTitle}</title>
                {metaDescription && (
                  <meta name="description" content={metaDescription} />
                )}
                {openGraphTitle && (
                  <meta property="og:title" content={openGraphTitle} />
                )}
                {metaDescription && (
                  <meta property="og:description" content={metaDescription} />
                )}
                <meta property="og:type" content="website"></meta>
                <script type="application/ld+json">
                  {JSON.stringify(jsonLd)}
                </script>
                {getUrl() && <meta property="og:url" content={getUrl()} />}
                <meta property="og:image" content={openGraphImageUrlFallback} />
                <link rel="canonical" href={getUrl()} />
              </Helmet>

              <Grid container>
                <Grid item xs={12} sm={12}>
                  <h1>
                    {messageCenterItem.Id} - {messageCenterItem.Title}
                  </h1>
                  <h2 className="MCTitle">
                    Primary Service: {messageCenterItem.PrimaryService}
                    &nbsp;&nbsp;&nbsp;Last Updated:{" "}
                    {new Date(messageCenterItem.LastUpdated).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )}
                  </h2>
                </Grid>
              </Grid>

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} marginBottom={5}>
                  <Grid item xs={6} sm={6}>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <div className="CategoryHeading">Admin Impact</div>
                      {ImpactChip(messageCenterItem.AdminImpact)}
                    </Stack>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <div className="CategoryHeading">User Impact</div>
                      {ImpactChip(messageCenterItem.UserImpact)}
                    </Stack>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <div className="CategoryHeading">Release Start</div>
                      {messageCenterItem.StandardStartDate !== minDate &&                      
                      messageCenterItem.StandardStartDate !== "0001-01-01T00:00:00" && (
                        <Chip
                          label={new Date(
                            messageCenterItem.StandardStartDate
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })}
                          className="genericChip"
                        />
                      )}
                        {(messageCenterItem.StandardStartDate === minDate ||                      
                      messageCenterItem.StandardStartDate === "0001-01-01T00:00:00") && (
                        <Chip
                          label="Unknown"
                          className="genericChip"
                        />
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <div className="CategoryHeading">Release End</div>
                      {messageCenterItem.StandardEndDate !== minDate &&
                      messageCenterItem.StandardEndDate !== "0001-01-01T00:00:00" && (
                        <Chip
                          label={new Date(
                            messageCenterItem.StandardEndDate
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })}
                          className="genericChip"
                        />
                      )}
                       {(messageCenterItem.StandardEndDate === minDate ||                      
                      messageCenterItem.StandardEndDate === "0001-01-01T00:00:00") && (
                        <Chip
                          label="Unknown"
                          className="genericChip"
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>

              <div className="MCDescription">
                <Paper elevation={3}>
                  <Box padding={1} margin={3}>
                    <h2>ChangePilot Summary:</h2>
                    <ul>
                      {descriptionPoints.map(
                        (point) =>
                          point.length > 0 && <li key={point}>{point}</li>
                      )}
                    </ul>

                    {messageCenterItem.VideoLinkUrl &&
                      messageCenterItem.MediaId &&
                      messageCenterItem.WatchTime && (
                        <div
                          className={`wistia_embed wistia_async_${messageCenterItem.MediaId} time=${messageCenterItem.WatchTime} keyMoments=false videoFoam=true playerColor=ff0000 popover=true popoverContent=html`}
                        >
                          <div>
                            <Button
                              color="primary"
                              size="large"
                              variant="outlined"
                              endIcon={<VideocamIcon />}
                            >
                              View ChangePilot item video
                            </Button>
                          </div>
                        </div>
                      )}
                  </Box>
                </Paper>
              </div>

              {/* <Paper
                elevation={0}
                className="dateBanner"
                style={{ marginTop: "2rem", marginBottom: "2rem" }}
              >
                <Grid item xs={12} sm={12}>
                  <div className="PublishingHeading">
                    Last updated:{" "}
                    {new Date(messageCenterItem.LastUpdated).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )} */}
              {/* {events.length > 0 && (
                      <> | Number of updates: {events.length}</>
                    )} */}
              {/* </div>
                </Grid>
              </Paper> */}

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} marginBottom={5}>
                  <Grid item xs={6} sm={4}>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                      <div className="CategoryHeading">Services</div>
                      <Stack
                        direction="row"
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "top",
                          flexWrap: "wrap",
                          listStyle: "none",
                          p: 0,
                          m: 0,
                        }}
                      >
                        {messageCenterItem.Services.length > 0 &&
                          services.map((service) => (
                            <Chip
                              key={service}
                              label={service}
                              className="genericChip"
                            />
                          ))}
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                      <div className="CategoryHeading">Category</div>
                      <Stack
                        direction="row"
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "top",
                          flexWrap: "wrap",
                          listStyle: "none",
                          p: 0,
                          m: 0,
                        }}
                      >
                        {messageCenterItem.Category.length > 0 && (
                          <Chip
                            label={messageCenterItem.Category}
                            className="genericChip"
                          />
                        )}
                        {messageCenterItem.Category.length === 0 && (
                          <Chip label="Unknown" className="genericChip" />
                        )}
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                      <div className="CategoryHeading">Tags</div>
                      <Stack
                        direction="row"
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "top",
                          flexWrap: "wrap",
                          listStyle: "none",
                          p: 0,
                          m: 0,
                        }}
                      >
                        {messageCenterItem.Tags.length > 0 &&
                          tags.map((tag) => (
                            <Chip
                              key={tag}
                              label={tag}
                              className="genericChip"
                            />
                          ))}
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <Container maxWidth="lg">
              <Paper elevation={0}>
                <h2>Microsoft Message</h2>
                <TextWithHeadings text={messageCenterItem.MsftDescription} />
              </Paper>
            </Container>

            {/* {events.length > 0 && (
            <Container maxWidth="lg">
              <Paper elevation={0}>
                <h2>Changes</h2>
                <Box marginTop={3}>
                  <ul>
                    {events.map((event) => (
                      <li key={event.timestamp}>
                        {new Date(event.timestamp).toLocaleDateString()} -{" "}
                        <b>{event.fieldName}</b> changed from{" "}
                        <i>{event.oldValue}</i> to <i>{event.newValue}</i>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Paper>
            </Container>
          )} */}
          </>
        )}

        {error && <p>{error}</p>}

        {!error && !messageCenterItem && isLoading && <p>Loading...</p>}

        {!error && !messageCenterItem && !isLoading && (
          <Container>
            <h1>Not Found</h1>
            <Paper elevation={0}>
              <Box margin={0}>
                <p>Sorry, Microsoft 365 Message Center item was not found.</p>

                <p>
                  It may be this item doesn't exist or we have not listed it
                  publicly.{" "}
                  <a href="https://changepilot.cloud">Login to ChangePilot</a>{" "}
                  for your tenant's complete list of message center items.
                </p>

                <p>
                  Each Microsoft 365 tenant's message center feed is different.
                  Microsoft customise the messages each tenant receives based on
                  services licenced, usage, endpoints, tenant type, and geo and
                  specific actions required.
                </p>

                <p>
                  Plan for Action and Prevent Or Fix Issue Messages may be more
                  specific to your tenant.
                </p>

                <p>
                  To find out the optimal way to manage Microsoft 365 changes
                  and maximise your investment, learn more about{" "}
                  <a href="https://changepilot.cloud">ChangePilot</a>
                </p>
              </Box>
            </Paper>
          </Container>
        )}
      </div>
    </>
  );
};

export default MessageCenterDetail;
