import axios from "axios";
import { MessageCenterItem } from "../Models/MessageCenterItem";
import { MessageCenterEvent } from "../Models/MessageCenterEvent";

const baseURL = "https://empoweringcloudapi.azurewebsites.net";

interface Site {
  mcid: string;
  title: string;
  updated: Date;
}

const getMessageCenterItem = async (
  messageCenterId: string
): Promise<MessageCenterItem> => {
  try {
    const response = await axios.get(
      `${baseURL}/MessageCenterInfo/${messageCenterId}`
    );
    const data = response.data;

    // Convert the JSON string to an object
    const responseObject: MessageCenterItem = data;

    return responseObject;
  } catch (error) {
    // Handle the error here
    
    console.log("Error calling the getMessageCenterItem API: ", error);
    throw error;
  }
};

const getMessageCenterEvents = async (
  messageCenterId: string
): Promise<MessageCenterEvent[]> => {
  try {
    const response = await axios.get(
      `${baseURL}/messagecenterevent/${messageCenterId}`
    );
    const data = response.data;

    // Convert the JSON string to an object
    const responseObject: MessageCenterEvent[] = data;

    return responseObject;
  } catch (error) {
    // Handle the error here
    console.log("Error calling the getMessageCenterEvents API: ", error);
    throw new Error("Failed to call the getMessageCenterEvents API");
  }
};

const getAllMessageCenterItems = async (): Promise<Site[]> => {
  try {
    const response = await axios.get(
      `${baseURL}/messagecenter/messagecenteritems`
    );
    const data = response.data;

    return data;
  } catch (error) {
    // Handle the error here
    console.log("Error calling the API: ", error);
    throw new Error("Failed to call the API");
  }
};

const apiService = {
  getMessageCenterItem,
  getAllMessageCenterItems,
  getMessageCenterEvents,
};
export default apiService;
