import React, { useEffect } from "react";

interface RedirectProps {
  url: string;
}

const Redirect: React.FC<RedirectProps> = (props) => {
  const { url } = props;
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <h5>Redirecting...</h5>;
};

export default Redirect;
